import { Tether, PopoverAnimation, Padding, Box, usePopover, Portal } from '@newfront-insurance/core-ui';
import { useProvider } from '@newfront-insurance/react-provision';
import { useMemo } from 'react';

import { AppSwitcherMenu } from './components/app-switcher-menu';
import { LayoutConfigProvider } from '../../../../config';
import type { AppType } from '../../../../metadata/apps';
import { canViewApp, getAppTypeMetadata, getAppTypes } from '../../../../metadata/apps';
import { ToolbarButton } from '../toolbar-button';

export function AppSwitcherButton(): JSX.Element | null {
  const { popoverRef, isPopoverOpen, togglePopover, targetRef } = usePopover<HTMLAnchorElement, HTMLDivElement>({
    excludeTarget: true,
  });

  const appTypes = useAppSwitcherItems();

  // There are no other apps available
  if (appTypes.length === 0) {
    return null;
  }

  return (
    <>
      <ToolbarButton
        tabIndex={0}
        title="Switch app"
        data-active={isPopoverOpen}
        onClick={togglePopover}
        ref={targetRef}
      >
        <AppSwitcherIcon />
      </ToolbarButton>
      <Portal name="popover">
        <Tether targetRef={targetRef} targetAnchor="bottomRight" anchor="topRight" yOffset={10} xOffset={0}>
          <PopoverAnimation isOpen={isPopoverOpen} direction="up">
            <Box backgroundColor="white" borderRadius={3} elementRef={popoverRef} boxShadow="overlay">
              <Padding y={8}>
                <AppSwitcherMenu types={appTypes} />
              </Padding>
            </Box>
          </PopoverAnimation>
        </Tether>
      </Portal>
    </>
  );
}

function AppSwitcherIcon(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M0 3.98671H3.84512V0H0V3.98671ZM5.76768 15.9468H9.61281V11.9601H5.76768V15.9468ZM0 15.9468H3.84512V11.9601H0V15.9468ZM0 9.96678H3.84512V5.98007H0V9.96678ZM5.76768 9.96678H9.61281V5.98007H5.76768V9.96678ZM11.5354 0V3.98671H15.3805V0H11.5354ZM5.76768 3.98671H9.61281V0H5.76768V3.98671ZM11.5354 9.96678H15.3805V5.98007H11.5354V9.96678ZM11.5354 15.9468H15.3805V11.9601H11.5354V15.9468Z"
        fill="currentColor"
      />
    </svg>
  );
}

function useAppSwitcherItems(): AppType[] {
  const { authProvider, currentApp } = useProvider(LayoutConfigProvider);
  const { userDetails: user } = useProvider(authProvider);

  // Get all of the app types and filter out the current app
  return useMemo(() => {
    return (
      getAppTypes()
        // Don't show the current app
        .filter((type) => type !== currentApp)

        // Filter app to ones set to show in menu
        .filter((type) => {
          const { showInMenu } = getAppTypeMetadata(type);
          return showInMenu;
        })

        // Filter the apps shown using the scopes on the user.
        .filter((type) => {
          if (!user) return false;
          return canViewApp(type, user.roles);
        })

        .sort()
    );
  }, [currentApp, user]);
}
